<template>
  <div>
    <v-card elevation="0">
      <div class="text-subtitle-2 pa-1 orange--text orange lighten-5">
        管理者モードでログインしています。
      </div>
      <div class="text-subtitle-2 pa-1 blue--text blue lighten-5">
        サーバ処理状況【過去24時間の処理件数】
      </div>
      <v-container fluid>
        <v-row>
          <v-col col="12" md="6">
            <v-card elevation="0">
              <v-card-title>
                <h4>自動更新</h4>
              </v-card-title>
              <!-- テーブル -->
              <v-data-table
                :headers="tc_headers"
                :items="tc_auto_desserts"
                :search="search"
                item-key="task_server_id"
                :mobile-breakpoint="0"
                :items-per-page="50"
                :footer-props="{
                  'items-per-page-options': [50, 100, -1],
                  'items-per-page-text': '表示件数',
                }"
                :hide-default-footer="true"
                class="table_border"
              >
                <!-- eslint-disable-next-line -->
                <template v-slot:footer.page-text="props">
                  {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
                    props.itemsLength
                  }}件
                </template>
                <template v-slot:no-data> 表示するデータがありません </template>
              </v-data-table>
            </v-card>
          </v-col>
          <v-col col="12" md="6">
            <v-card elevation="0">
              <v-card-title>
                <h4>手動更新</h4>
              </v-card-title>
              <!-- テーブル -->
              <v-data-table
                :headers="tc_headers"
                :items="tc_manual_desserts"
                :search="search"
                item-key="task_server_id"
                :mobile-breakpoint="0"
                :items-per-page="50"
                :footer-props="{
                  'items-per-page-options': [50, 100, -1],
                  'items-per-page-text': '表示件数',
                }"
                :hide-default-footer="true"
                class="table_border"
              >
                <!-- eslint-disable-next-line -->
                <template v-slot:footer.page-text="props">
                  {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
                    props.itemsLength
                  }}件
                </template>
                <template v-slot:no-data> 表示するデータがありません </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";

export default {
  setup(props, ctx) {
    const ResultLogsRepository = repositoryFactory.get("resultLogs");
    const state = reactive({
      search: "",
      tc_headers: [
        {
          text: "サーバーID",
          value: "task_server_id",
          sortable: true,
          align: "center",
        },
        {
          text: " ステータス",
          value: "result",
          sortable: true,
        },
        {
          text: "件数",
          value: "count",
          sortable: true,
          align: "center",
        },
      ],
      tc_auto_desserts: [],
      tc_manual_desserts: [],
    });

    // 初期化処理(同期処理)
    const init = async () => {};

    // 初期化する
    init();

    // 初期化処理
    onMounted(async () => {
      // ローディング表示
      store.dispatch("loadingIcon/showIcon");

      // ログ情報取得(task_server_count)
      await ResultLogsRepository.admin_task_server_count()
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach((key) => {
              const tmp = response.data[key];
              tmp.log_category = tmp.log_category.replace("lib.task.", "");
              if (tmp.log_category == "update_timer") {
                state.tc_auto_desserts.push(tmp);
              } else if (tmp.log_category == "manual_update") {
                state.tc_manual_desserts.push(tmp);
              }
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:ResultLogsRepository.admin_task_server_count (" + error + ")"
          );
        });

      // ローディング非表示
      store.dispatch("loadingIcon/hideIcon");
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style scoped>
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_task_count {
  text-align: right !important;
}
.table_border {
  border: 1px solid #ddd !important;
  border-radius: 0.5em !important;
}
</style>
